import classes from './Home.module.scss';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Link } from 'react-router-dom';
import SearchList from './SearchList';
import Footer from './Footer';

const slideImages = [
    '/home/header-image1.jpg',
    '/home/header-image2.jpg',
    '/home/header-image3.jpg',
];

const Home = (props) => {
    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <Fade arrows={false} easing="ease">
                    {slideImages.map((image, index) => (
                        <div className={classes.eachSlide} key={index}>
                            <img src={image} alt={`Header ${index + 1}`} className={classes.headerImage} />
                        </div>
                    ))}
                </Fade>
                <div className={classes.box}>
                    <div className={classes.logo}><img src="/logo.svg" alt="Logo" /></div>
                    <div className={classes.title}>「日本の伝統文化を守る」</div>
                </div>
            </header>

            <div className={classes.subTitle}>
                <div className={classes.text1}>失われつつあるお寺とお坊さんの</div>
                <div className={classes.text2}>価値を最大限に高める！</div>
            </div>

            <section className={classes.search}>
                <p className={classes.searchTitle}>
                    <img src="/home/search-title-side.svg" alt="Search" />
                    お近くのお寺をかんたん検索
                    <img src="/home/search-title-side.svg" alt="Search" />
                </p>
                <Link to="/search" className={classes.searchButton}>現在地からお寺を探す</Link>
                <Link to="/map" className={classes.quickButton}>本日対応可能なお寺を探す</Link>
                {/* <a href="tel:076-287-0026" className={classes.telButton}>
                    <div>
                        お気軽にご相談ください<br />
                        <b>お客様サポートダイアル</b>
                    </div>
                    <div>076-287-0026</div>
                </a> */}
                <a href="mailto:sales@collabo.link" className={classes.mailButton}><div>
                        お気軽にご相談ください<br />
                        <b>お客様サポートメール</b>
                    </div>
                    <div>sales@collabo.link</div>
                </a>

                {false && <div className={classes.searchInput}>
                    <input type="text" placeholder="郵便番号・住所・施設名などで検索" className={classes.searchInput} />
                    <img src="/home/search-bt.svg" alt="Search" className={classes.icon} onClick={() => {
                        window.location.href = "/search";
                    }}/>
                </div>}
            </section>
            <br />
            {false && <><section className={classes.section}>
                <h2>全国からお寺を探す</h2>
                <div className={classes.regions}>
                    <Link to="/search/region/hokkaido">北海道・東北</Link>
                    <Link to="/search/region/kanto">関東</Link>
                    <Link to="/search/region/tohoka">東海</Link>
                    <Link to="/search/region/kansai">関西</Link>
                    <Link to="/search/region/chugoku">中国・四国</Link>
                    <Link to="/search/region/kyushu">九州・沖縄</Link>
                </div>
            </section>
            <br /></>}
            <SearchList />
            <section className={classes.features}>
                <h2>「お寺のお葬式」が<br />選ばれる<span>５</span>つの理由</h2>
                <div className={classes.feature}>
                    <h3>選ばれる理由01</h3>
                    <h4>簡単依頼、簡単見積もり</h4>
                    <p>お急ぎの場合や事前の準備等のご依頼も対応可能です。</p>
                    <img src="/home/feature1.svg" alt="Feature" />
                </div>
                <div className={classes.feature}>
                    <h3>選ばれる理由02</h3>
                    <h4>込み込みの安心プラン</h4>
                    <p>安心プランの中には搬送、斎場、お経、火葬場が含まれています。お寺によっては複数のプランから選択できます。</p>
                    <img src="/home/feature2.svg" alt="Feature" />
                </div>
                <div className={classes.feature}>
                    <h3>選ばれる理由03</h3>
                    <h4>供養を目的にした、低価格設定</h4>
                    <p>寺マッチではお寺のみの紹介なので葬儀にかかるコストを抑えつつも、仏教のしっかりとした供養ができます。</p>
                    <img src="/home/feature3.svg" alt="Feature" />
                </div>
                <div className={classes.feature}>
                    <h3>選ばれる理由04</h3>
                    <h4>ピッタリのお寺が見つかる</h4>
                    <p>場所、価格、宗派等の条件を絞りながらお寺を比較して選ぶことが可能なので希望にあったお寺がみつかります。</p>
                    <img src="/home/feature4.png" alt="Feature" />
                </div>
                <div className={classes.feature}>
                    <h3>選ばれる理由05</h3>
                    <h4>依頼があってから搬送までスムーズ</h4>
                    <p>お寺のお葬式では、24時間体制のため依頼を受けてから安置場所までの搬送をスムーズに行うことができます。</p>
                    <img src="/home/feature5.svg" alt="Feature" />
                </div>
            </section>
            <section className={classes.steps}>
                <h2>ご依頼の流れ</h2>
                <div className={classes.step}>
                    <div className={classes.number}>1</div>
                    <img src="/home/step1.svg" alt="Step" className={classes.icon} />
                    <div className={classes.titleBox}>
                        <div className={classes.title}>寺院選択</div>
                        <div className={classes.text}>地図、リストから希望する寺院を選択してください</div>
                    </div>
                </div>
                <div className={classes.step}>
                    <div className={classes.number}>2</div>
                    <img src="/home/step2.svg" alt="Step" className={classes.icon} />
                    <div className={classes.titleBox}>
                        <div className={classes.title}>プラン選択</div>
                        <div className={classes.text}>寺院選択後にご希望のプランを寺院ページから選択</div>
                    </div>
                </div>
                <div className={classes.step}>
                    <div className={classes.number}>3</div>
                    <img src="/home/step3.svg" alt="Step" className={classes.icon} />
                    <div className={classes.titleBox}>
                        <div className={classes.title}>お申込み</div>
                        <div className={classes.text}>寺院選択後お申込みフォームから必要事項を入力しご依頼してください</div>
                    </div>
                </div>
                <div className={classes.step}>
                    <div className={classes.number}>4</div>
                    <img src="/home/step4.svg" alt="Step" className={classes.icon} />
                    <div className={classes.titleBox}>
                        <div className={classes.title}>ご依頼確定</div>
                        <div className={classes.text}>選択した寺院の確保ができましたら運営の方からご連絡致します</div>
                    </div>
                </div>
            </section>
            <section className={classes.qa}>
                <h2>Q&A</h2>
                <div className={classes.qaBox}>
                    <div className={classes.item}>
                        <div className={classes.q}>お寺のお葬式とは？</div>
                        <div className={classes.a}>お寺のお葬式とは、お寺を会場にして行う伝統的な形式の葬儀です。お寺の本堂で故人を送り出すことができ、宗教的な儀式を重視する方に適しています。</div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.q}>お寺のお葬式はいくらから葬儀が行えますか？</div>
                        <div className={classes.a}>お寺でのお葬式は、規模や地域によりますが、基本的な費用は30万円からのプランが多く見られます。詳細な費用については、お寺や葬儀社に直接お問い合わせください。</div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.q}>本当にセットプランの内容で葬儀ができますか？</div>
                        <div className={classes.a}>はい、セットプランには葬儀に必要な基本的なサービスが含まれています。ただし、特別な希望がある場合は、別途料金が発生することがありますので、事前に確認することをおすすめします。</div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.q}>自宅で安置出来ない。どうしたらいいですか？</div>
                        <div className={classes.a}>自宅での安置が難しい場合は、提携の安置施設やお寺の施設をご案内いたします。ご相談いただければ、適切な施設を手配いたします。</div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.q}>寺院の紹介を依頼すると、檀家にならなければなりませんか？</div>
                        <div className={classes.a}>寺院の紹介を受けた場合でも、必ずしも檀家になる必要はありません。葬儀のみの対応も可能ですので、ご希望をお知らせください。</div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.q}>菩提寺（檀家となっている寺院）があるので、そちらに来てもらうことは可能ですか？</div>
                        <div className={classes.a}>はい、菩提寺の僧侶に来ていただくことが可能です。事前に菩提寺と連絡を取り、日程や費用について調整してください。</div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.q}>寺院のお手配は、全ての宗派でご対応頂けますか？</div>
                        <div className={classes.a}>はい、全ての宗派に対応した寺院のお手配が可能です。宗派に応じた適切な僧侶をご紹介いたします。</div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.q}>僧侶にお布施とは別にお車代や御膳料はどれほど包めばよろしいでしょうか？</div>
                        <div className={classes.a}>お車代や御膳料は、地域や寺院によりますが、一般的にはお車代として5,000円〜1万円、御膳料として5,000円〜1万円が目安です。具体的な金額は寺院にご確認ください。</div>
                    </div>
                    <div className={classes.item}>
                        <div className={classes.q}>法事・法要の僧侶の手配をお願いしたいのですが可能でしょうか？</div>
                        <div className={classes.a}>はい、法事・法要の僧侶の手配も可能です。ご希望の日時や宗派をお知らせいただければ、適切な僧侶を手配し、法要の運営をサポートいたします。</div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Home;