import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import axios from "axios";
import { appLib } from "./lib/_app_lib";
import { Box, Dialog, DialogContent, DialogActions, TextField, Button } from "@mui/material";
import classes from "./Map.module.scss";
import SearchIcon from '@mui/icons-material/Search';
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ReactComponent as PostIconSvg } from './posticon.svg';
import LoadingScreen from "./lib/LoadingScreen";

const Map = (props) => {
    const [center, setCenter] = useState([36.680147529099855, 136.85057401657107]);
    const [zoom, setZoom] = useState(10)
    const [zip, setZip] = useState('')
    const [zipDialogOpen, setZipDialogOpen] = useState(false)
    const [address, setAddress] = useState('')
    const [addressDialogOpen, setAddressDialogOpen] = useState(false)
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)


    const ChangeView = ({ center, zoom }) => {
        console.log(center, zoom)
        const map = useMap();
        useEffect(() => {
            map.setView(center, zoom);

            // Add dragend event listener
            map.on('dragend', () => {
                const center = map.getCenter();
                console.log('dragend', center)
                setCenter([center.lat, center.lng])
                const zoom = map.getZoom();
                console.log('zoomend', zoom)
                setZoom(zoom)
            });
            map.on('zoomend', () => {
                const zoom = map.getZoom();
                console.log('zoomend', zoom)
                setZoom(zoom)
                const center = map.getCenter();
                console.log('dragend', center)
                setCenter([center.lat, center.lng])
            });

            // Cleanup listener on unmount
            return () => {
                map.off('dragend');
            };
        }, [center, zoom, map]);

        return null;
    }

    const handleApply = async (_center) => {

        const centerValue = _center ? _center : center

        console.log('apply', centerValue)

        setIsLoading(true)

        const url = `https://geoapi.heartrails.com/api/json?method=searchByGeoLocation&x=${centerValue[1]}&y=${centerValue[0]}`;

        try {
            const response = await axios.get(url);
            console.log(response)
            if (response.data.response && response.data.response.location) {
                const data = response.data.response.location[0]
                setAddress(`${data.prefecture}${data.city}${data.town}`)
                setAddressDialogOpen(true)
            } else {
                alert("住所情報を取得できませんでした。");
            }
        } catch (error) {
            console.error("エラー:", error);
            alert("エラーが発生しました。");
        }


        setIsLoading(false)
    }

    const handleGetLocation = async () => {
        console.log('get location')
        setIsLoading(true)
        const location = await appLib.GetLocation()

        if (location.status) {
            setZoom(15)
            setCenter(location.pos)
        }

        setTimeout(() => {
            setZoom(15)
            setCenter(location.pos)
            handleApply(location.pos)
        }, 500)
    }

    const handleGetLocationByZip = async () => {
        console.log('get location by zip')
        setIsLoading(true)
        try {
            const response = await axios.get(`https://zipcloud.ibsnet.co.jp/api/search?zipcode=${zip}`);
            const data = response.data;

            if (data.results) {
                const address = data.results[0];
                setAddress(`${address.address1}${address.address2}${address.address3}`)
                setAddressDialogOpen(true)
                setZipDialogOpen(false)
            } else {
                alert('該当する住所が見つかりませんでした。');
            }
        } catch (error) {
            console.error('エラー:', error);
            alert('エラーが発生しました。');
        }
        setIsLoading(false)
    }

    const handleGotoForm = () => {
        setAddressDialogOpen(false)
        history.push(`/request-quick/${address}/${center[0]}/${center[1]}`)
    }

    return (
        <>
            <LoadingScreen show={isLoading} />
            <div className={classes.header}>
                <Box className={classes.backButton} onClick={() => history.goBack()}>
                    <CloseIcon />
                </Box>
                <div className={classes.text}>場所を選択してください</div>
            </div>
            <Box className={classes.mapContainer}>
                <MapContainer
                    center={center}
                    zoom={zoom}
                    style={{ width: "100%", height: "100%" }}
                    zoomControl={false}
                >
                    <ChangeView center={center} zoom={zoom} />
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                </MapContainer>
                <div className={classes.crosshair} />
            </Box>
            <Box className={classes.bottom}>
                <Box className={classes.applyButton} onClick={()=>handleApply()}>
                    この場所で決定
                </Box>
                <div className={classes.row}>
                    <Box className={classes.button} onClick={handleGetLocation}>
                        <LocationOnIcon sx={{ mr: 1 }} />
                        現在地から取得
                    </Box>
                    <Box className={classes.button} onClick={() => setZipDialogOpen(true)}>
                        <PostIconSvg className={classes.icon} />
                        郵便番号から取得
                    </Box>
                </div>
            </Box>
            <Dialog
                open={zipDialogOpen}
                onClose={() => setZipDialogOpen(false)}
                PaperProps={{
                    style: {
                        overflowY: 'visible'
                    }
                }}
            >
                <div className={classes.zipDialog}>
                    <TextField
                        label="郵便番号"
                        value={zip}
                        onChange={(e) => setZip(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={handleGetLocationByZip}>
                        <SearchIcon />
                    </Button>
                </div>
            </Dialog>
            <Dialog open={addressDialogOpen} onClose={() => setAddressDialogOpen(false)} fullWidth >
                <DialogContent>
                    <TextField
                        fullWidth
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddressDialogOpen(false)}>
                        キャンセル
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleGotoForm}>
                        この住所で決定
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Map;
