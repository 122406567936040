import { AuthProvider } from './context/lib/AuthContext';
import './App.css';
import { BrowserRouter, Switch, } from 'react-router-dom';
import ScrollToTop from './components/lib/ScrollToTop';
import FirebaseAnalytics from './components/lib/FirebaseAnalytics';
import NotFound from './components/NotFound';
import AppLayout from './components/AppLayout';
import AppRoute from './components/AppRoute';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from 'dayjs/plugin/duration';
import "dayjs/locale/ja";
import './components/lib/firebase';
import ShopList from './components/ShopList';
import ViewList from './components/lib/ViewList';
import Recruit from './components/Recruit';
import RecruitComplete from './components/RecruitComplete';
import ViewMailLogin from './components/ViewMailLogin';
import Logout from './components/lib/Logout';
import ViewLogin from './components/ViewLogin';
import ProfileEdit from './components/lib/ProfileEdit';
import RecruitStart from './components/RecruitStart';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import CallKitchenCar from './components/CallKitchenCar';
import CallKitchenComplete from './components/CallKitchenCarComplete';
import { CartProvider } from './context/lib/CartContext';
import MenuLayout from './components/MenuLayout';
import AppSupport from './components/AppSupport';
import SpBoot from './components/SpBoot';
import ShopListAdmin from './components/ShopListAdmin';
import AccountEditMenu from './components/lib/AccountEditMenu';
import UnsubscribeSuccess from './components/UnsubscribeSuccess';
import Home from './components/Home';
import Temple from './components/Temple';
import Home2 from './components/Home2';
import ShopList2 from './components/ShopList2';
import EventSummer1 from './components/events/EventSummer1';
import NotificationEdit from './components/lib/NotificationEdit';
import AdminUserAdd from './components/lib/AdminUserAdd';
import { GeocodeProvider } from './context/lib/GeocodeContext';
import AdminLayout from './components/AdminLayout';
import PasswordEdit from './components/lib/PasswordEdit';
import MapRoute from './components/MapRoute';
import MapRoute2 from './components/MapRoute2';
import MapRoute3 from './components/MapRoute3';
import RecruitDetails from './components/RecruitDetails';
import Search from './components/Search';
import Request from './components/Request';
import RequestQuick from './components/RequestQuick';
import SingleLayout from './components/SingleLayout';
import Map from './components/Map';
// 相対時間の有効化
dayjs.extend(relativeTime);
dayjs.extend(duration);

// ロケールを日本語に設定
dayjs.locale(`ja`);


// エラー監視（ローカルホスト以外）
if(!window.location.hostname.startsWith("localhost"))
{
    Sentry.init({
        dsn: "https://c02ee2d4b484db38f717483a834e5c21@o4504858742226944.ingest.us.sentry.io/4507779792109568",

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [new BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 1.0,
    });
}


const App = () => {

    return (
        <AuthProvider>
            <BrowserRouter>
                <CartProvider>
                    <FirebaseAnalytics />
                    <ScrollToTop />
                    
                    <GeocodeProvider>
                        <Switch>
                            <AppRoute  exact path="/" component={Home} layout={MenuLayout} />
                            <AppRoute  exact path="/temple/:uuid" component={Temple} layout={MenuLayout} />
                            <AppRoute  exact path="/search" component={Search} layout={MenuLayout} />
                            <AppRoute  exact path="/search/region/:region" component={Search} layout={MenuLayout} />
                            <AppRoute  exact path="/request" component={Request} layout={SingleLayout} />
                            <AppRoute  exact path="/request/:uuid/:name" component={Request} layout={SingleLayout} />
                            <AppRoute  exact path="/request-quick/:address/:lat/:lng" component={RequestQuick} layout={SingleLayout} />
                            <AppRoute  exact path="/home2" component={Home2} layout={MenuLayout} />
                            <AppRoute  exact path="/shoplist" component={ShopList} layout={MenuLayout} />
                            <AppRoute  exact path="/shoplist/:area/:genre" component={ShopList2} layout={MenuLayout} />
                            <AppRoute  exact path="/shop" component={ViewList} layout={MenuLayout} />
                            <AppRoute  exact path="/shop/:uuid" component={ViewList} layout={MenuLayout} />
                            <AppRoute  exact path="/recruit-start" component={RecruitStart} layout={AppLayout} />
                            <AppRoute  exact path="/recruit" component={Recruit} layout={AppLayout} />
                            <AppRoute  exact path="/recruit/complete" component={RecruitComplete} layout={AppLayout} />
                            <AppRoute  exact path="/recruit-details" component={RecruitDetails} layout={AppLayout} />
                            <AppRoute  exact path="/sp-boot" component={SpBoot} layout={AppLayout} />
                            <AppRoute  exact path="/map" component={Map} layout={AppLayout} />
                            
                            <AppRoute  exact path="/__mail-login" component={ViewMailLogin} layout={AppLayout}/>
                            <AppRoute  exact path="/logout"      component={Logout} layout={AppLayout}/>
                            <AppRoute  exact path="/authlogin"      component={ViewLogin} layout={AppLayout}/>
                            <AppRoute  exact path="/profile/edit" component={ ProfileEdit } authCheck={true} layout={MenuLayout}/>
                            <AppRoute  exact path="/profile/edit/:uid" component={ ProfileEdit } authCheck={true} layout={MenuLayout}/>
                            <AppRoute  exact path="/notification/edit" component={ NotificationEdit } authCheck={true} layout={MenuLayout}/>
                            <AppRoute  exact path="/password/edit" component={ PasswordEdit } authCheck={true} layout={MenuLayout}/>
                            <AppRoute  exact path="/call-kitchen-car" component={ CallKitchenCar } layout={AppLayout}/>
                            <AppRoute  exact path="/call-kitchen-car/complete" component={CallKitchenComplete} layout={AppLayout} />
                            <AppRoute  exact path="/account/edit" component={ AccountEditMenu } authCheck={true} layout={MenuLayout}/>
                            <AppRoute  exact path="/unsubscribe/success" component={UnsubscribeSuccess} authCheck={false} layout={AppLayout}/>
                            <AppRoute  exact path="/event/summer1" component={EventSummer1} authCheck={false} layout={AppLayout}/>
                            <AppRoute  exact path="/route1/:address" component={MapRoute} authCheck={false} layout={MenuLayout}/>
                            <AppRoute  exact path="/route2/:geocode1/:geocode2" component={MapRoute2} authCheck={false} layout={MenuLayout}/>
                            <AppRoute  exact path="/route3/:geocode1/:geocode2" component={MapRoute3} authCheck={false} layout={MenuLayout}/>

                            <AppRoute  exact path="/admin/add_user" component={ AdminUserAdd } authCheck={true} layout={MenuLayout}/>
                            <AppRoute  exact path="/admin/shoplist" component={ShopListAdmin} authCheck={true} layout={AdminLayout} />
                            

                            <AppRoute  exact path="/privacy" component={Privacy} layout={AppLayout}/>
                            <AppRoute  exact path="/terms" component={Terms} layout={AppLayout}/>
                            <AppRoute  exact path="/app-support" component={AppSupport} layout={AppLayout} />

                            <AppRoute  component={NotFound} status={404} layout={AppLayout} />
                        </Switch>
                    </GeocodeProvider>

                </CartProvider>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
