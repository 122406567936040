import classes from './Footer.module.scss';


const Footer = (props) => {

    
    return (
        <div className={classes.root}>
                
            <img alt="collabo株式会社" className={ classes.logo } src="/collabo-logo.svg" />
            <div className={ classes.text }>
                コラボ株式会社<br />
                石川県金沢市横川 ７丁目２６ ２F<br />
                {/* 電話： 076-287-0026 */}
                <a href="mailto:sales@collabo.link">sales@collabo.link</a>
            </div>
            
        </div>
    );
};

export default Footer;